import { LoadingIndicator } from '@cfa/react-components';
import { parseISO } from 'date-fns';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import EventSummary from 'src/components/EventSummary/EventSummary';
import Icon from 'src/components/Icon';
import OrderSummary from 'src/components/OrderSummary/OrderSummary';
import OverlayModal from 'src/components/OverlayModal';
import Totals from 'src/components/Totals/Totals';
import DashboardError from 'src/dashboard/components/DashboardError/DashboardError';
import OrderHistory from 'src/dashboard/components/OrderHistory/OrderHistory';
import { selectIsPOSOrder, selectOrderHistory, selectOrderToView } from 'src/dashboard/selectors';
import { selectDetailViewErrors } from 'src/reducers';
import { selectLocationContactDetails } from 'src/reducers/user';
import { DashboardOrder, DashboardOrderDisplay } from 'src/types/dashboard';
import constants from '../../../constants';
import { actions as dashboardActions } from '../../../reducers/dashboard';
import history from '../../../util/history';
import DashboardActions from '../DashboardActions/DashboardActions';
import DetailViewHeader from './DetailViewHeader';

interface DetailViewProps {
  baseOrder?: DashboardOrder & DashboardOrderDisplay;
  orderDetails: ReturnType<typeof selectOrderToView>;
  lookupDetailsLoading: boolean;
  dashboardActionLoading: boolean;
}

const DetailView: React.FC<DetailViewProps> = ({
  baseOrder,
  orderDetails,
  lookupDetailsLoading = false,
  dashboardActionLoading = false,
}) => {
  const dispatch = useDispatch();

  const isPOSOrder = useSelector(selectIsPOSOrder);
  const restaurantContactDetails = useSelector(selectLocationContactDetails);
  const orderHistory = useSelector(selectOrderHistory);
  const detailViewErrors = useSelector(selectDetailViewErrors);

  useEffect(() => {
    if (baseOrder) {
      dispatch(dashboardActions.lookupOrderDetails(baseOrder.id, baseOrder.cfaId || ''));
      if (baseOrder.cfaId) {
        dispatch(dashboardActions.lookupCustomerDetails(baseOrder.id, baseOrder.cfaId));
      }
    }
  }, []);

  const {
    promiseDateTime,
    time,
    lineItems,
    deliveryAddress,
    companyName,
    email,
    phone,
    guestCount,
    paperGoods,
    givenName,
    familyName,
    destination,
    specialInstructions,
    displaySubtotal,
    displayPrice,
    cfaId,
    transId,
    deliveryTip,
    cateringReason,
    secondaryContact,
    displayTaxAmount,
  } = orderDetails;
  const eventDetails = {
    guestCount,
    paperGoods,
    specialInstructions,
    time,
    date: promiseDateTime ? parseISO(promiseDateTime) : {},
  };
  const deliveryAddressWithCompany = {
    ...deliveryAddress,
    companyName,
  };
  const guestDetails = {
    email,
    phone,
    cfaId,
    first: givenName,
    last: familyName,
  };
  const close = () => history.push('/more/dashboard');
  const deliveryTipIsDisplayed = deliveryTip && Object.keys(deliveryTip).length > 0;

  return (
    <OverlayModal close={close} data-cy="overlay-modal" isOpen minWidth="65%">
      {lookupDetailsLoading ? (
        <div style={{ justifyItems: 'center', alignItems: 'center' }}>
          <LoadingIndicator className="detail-spinner" data-testid="cfa-spinner" size="md" variant="page" />
        </div>
      ) : (
        <>
          <div className="main-content">
            <DetailViewHeader
              close={close}
              dashboardActionLoading={dashboardActionLoading}
              orderDetails={orderDetails}
            />
            <EventSummary
              addressValidated
              cateringReason={cateringReason}
              className="event-summary"
              deliveryAddress={isPOSOrder ? deliveryAddress : deliveryAddressWithCompany}
              destination={destination}
              eventDetails={eventDetails}
              eventSummaryValid
              guestDetails={guestDetails}
              isPOSOrder={isPOSOrder}
              restaurantContactDetails={restaurantContactDetails}
              secondaryContactDetails={secondaryContact}
              transId={transId}
            />
            <h3>Order Summary</h3>
            <OrderSummary cartItems={lineItems} />
            <Totals
              deliveryTip={deliveryTip}
              deliveryTipIsDisplayed={deliveryTipIsDisplayed}
              subTotal={displaySubtotal}
              taxAmount={displayTaxAmount}
              taxAndTotal={displayPrice}
            />
            {!isPOSOrder && <OrderHistory orderHistory={orderHistory} />}
          </div>
          <div className="errors-warnings-box">
            <DashboardError detailViewErrors={detailViewErrors} />
            {isPOSOrder && (
              <PosOrderWarningBox>
                <Icon className="icn">warning</Icon>
                <div>{constants.POS_ORDER_WARNING_TEXT}</div>
              </PosOrderWarningBox>
            )}
          </div>
          <DashboardActions
            dashboardActionLoading={dashboardActionLoading}
            guestDetails={guestDetails}
            isPOSOrder={isPOSOrder}
            orderDetails={orderDetails}
            restaurantContactDetails={restaurantContactDetails}
          />
        </>
      )}
    </OverlayModal>
  );
};

const PosOrderWarningBox = styled.div`
  font: ${(props) => props.theme.smallBoldFont};
  margin: 10px;
  padding: 0 7px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.colors.warning};
  border: 1px solid ${(props) => props.theme.colors.warning};
  background-color: #fffcee;

  & .icn {
    min-width: 17px;
  }

  & svg {
    width: 17px;
    margin: 0 5px 0 0;
  }
`;

const StyledDetailView = styled(DetailView)`
  & .main-content {
    padding: 20px;
  }

  & .event-summary {
    margin: 20px 0 40px;
  }

  & .detail-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
  }

  @media (max-width: ${(props) => props.theme.phone}) {
    & .detail-spinner {
      margin-top: 33vh;
    }

    & .errors-warnings-box {
      margin-bottom: 130px;
    }
  }
`;

export default StyledDetailView;
