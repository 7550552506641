import '@cfa/react-components/dist/styles.min.css';

import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Cart from './cart';
import { selectCartItemsLength } from './cart/selectors';
import AddNewCard from './components/AddNewCard';
import DeviceStatusMessage from './components/DeviceStatusMessage';
import ErrorMessage from './components/ErrorMessage';
import FullScreenError from './components/ErrorMessage/FullScreenError';
import Eula from './components/Eula/Eula';
import Footer from './components/Footer/Footer';
import Icon from './components/Icon';
import InitialLoad from './components/InitialLoad/InitialLoad';
import Logo from './components/Logo/Logo';
import CartLink from './components/NavComponents/CartLink';
import RightDesktop from './components/NavComponents/RightDesktop';
import UserLocation from './components/NavComponents/UserLocation';
import Navigation from './components/Navigation/Navigation';
import MoreTab from './components/Tabs/MoreTab';
import NavTab from './components/Tabs/NavTab';
import Tabs from './components/Tabs/Tabs';
import ooeConstants from './constants';
import Dashboard from './dashboard';
import EventDetails from './eventDetails';
import FloatingMenu from './floatingMenu';
import Guest from './guest';
import Menu from './menu';
import More from './more';
import Payment from './payment';
import { selectTaxAndTotal, State } from './reducers';
import { actions as dashboardActions } from './reducers/dashboard';
import { selectDisplayMessage } from './reducers/device';
import { actions as formActions } from './reducers/form';
import { actions as guestActions } from './reducers/guest';
import { actions as menuActions } from './reducers/menu';
import { actions as orderActions, selectDestination, selectOrderIsLoading } from './reducers/order';
import {
  isAdminUser as getIsAdminUserFromState,
  selectAllStoreNamesAndNumbers,
  selectAppLoadingComplete,
  selectEulaText,
  selectIsUserLoggedIn,
  selectLocationDropdownDisabled,
  selectLocationNumber,
  selectOktaTokenError,
  selectShouldBypassBusinessRules,
  selectShowHardcodedEulaText,
  selectUserHasAgreedToEula,
  selectUserLocations,
  selectUserName,
  actions as userActions,
} from './reducers/user';
import { logPageView } from './services/analytics';
import Submit from './submit';
import theme from './theme';
import usePrevious from './util/customHooks';
import { getQueryVariable } from './util/routerHelpers';

export const App: React.FC<Props> = ({
  location,
  getTokenFromStorage = () => {},
  setCustomerPhoneNumber = () => {},
  addExternalOrderId = () => {},
  cartItemCount = 0,
  cartTotal = '',
  cartLoading = false,
  userName = '',
  locations = [],
  updateUserLocation = () => {},
  selectedLocation = '',
  appLoadingComplete = false,
  logoutUser = () => {},
  userHasAgreedToEula = false,
  addUserToAcknowledgement = () => {},
  eulaText = '',
  oktaError = false,
  throwFullScreenError = () => {},
  displayDeviceMessage = false,
  userIsLoggedIn = false,
  locationDropdownDisabled = false,
  shouldBypassBusinessRules = false,
  toggleBypass = () => {},
  lookupLocation = () => {},
  isAdminUser = false,
  allStoreNamesAndNumbers = [],
  isHardcodedEulaShown = false,
  resetDateFormValues = () => {},
  resetTimeFormValues = () => {},
}) => {
  let previousLocation = location;
  const history = useHistory();

  useEffect(() => {
    const { search, pathname, hash } = previousLocation;
    const routeToStore = `${pathname}${search}`;
    let storedRoute = localStorage.getItem(ooeConstants.ROUTE_TO_REDIRECT_TO);
    if (!storedRoute) {
      localStorage.setItem(ooeConstants.ROUTE_TO_REDIRECT_TO, routeToStore);
    }

    getTokenFromStorage();

    storedRoute = localStorage.getItem(ooeConstants.ROUTE_TO_REDIRECT_TO);
    if (storedRoute && hash) {
      history.replace(storedRoute);
      localStorage.removeItem(ooeConstants.ROUTE_TO_REDIRECT_TO);
    }

    if (getQueryVariable('phoneNumber')) {
      setCustomerPhoneNumber(getQueryVariable('phoneNumber') || '');
    }

    if (getQueryVariable('callId')) {
      const callSource = getQueryVariable('callSource') || ooeConstants.VCA_INKTEL;
      addExternalOrderId(getQueryVariable('callId') || '', callSource);
    }

    history.listen((loc) => {
      logPageView(loc.pathname);
    });
  }, []);

  const prevProps = usePrevious({ location });
  const prevPropsLocation = prevProps?.location;

  useEffect(() => {
    if (history?.action !== 'POP' && (!prevPropsLocation?.state || !prevPropsLocation?.state?.modal)) {
      previousLocation = prevProps?.location;
    }
  });

  const { pathname } = location;
  const isModal = !!(location.state && location.state.modal);

  if (oktaError && userIsLoggedIn && window.location.pathname !== '/error') {
    throwFullScreenError();
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        {appLoadingComplete && !userHasAgreedToEula && !window.Cypress && (
          <Eula
            addUserToEula={addUserToAcknowledgement}
            eulaText={eulaText}
            isHardcodedEulaShown={isHardcodedEulaShown}
            logoutUser={logoutUser}
          />
        )}
        {(appLoadingComplete || window.location.pathname === '/error') && (
          <div style={{ minHeight: '97vh' }}>
            {displayDeviceMessage && <DeviceStatusMessage />}
            <Navigation
              leftDesktop={<Logo className="" />}
              leftMobile={
                !oktaError ? (
                  <UserLocation
                    allStoreNamesAndNumbers={allStoreNamesAndNumbers}
                    locationDropdownDisabled={locationDropdownDisabled}
                    locations={locations}
                    lookupLocation={lookupLocation}
                    resetDateFormValues={resetDateFormValues}
                    resetTimeFormValues={resetTimeFormValues}
                    selectedLocation={selectedLocation}
                    updateLocation={updateUserLocation}
                  />
                ) : null
              }
              pathname={pathname}
              rightDesktop={
                !oktaError ? (
                  <RightDesktop
                    allStoreNamesAndNumbers={allStoreNamesAndNumbers}
                    cartItemCount={cartItemCount}
                    icon="bag"
                    isAdminUser={isAdminUser}
                    loading={cartLoading}
                    locationDropdownDisabled={locationDropdownDisabled}
                    locations={locations}
                    logoutUser={logoutUser}
                    lookupLocation={lookupLocation}
                    resetDateFormValues={resetDateFormValues}
                    resetTimeFormValues={resetTimeFormValues}
                    selectedLocation={selectedLocation}
                    shouldBypassBusinessRules={shouldBypassBusinessRules}
                    toggleBypass={toggleBypass}
                    total={cartTotal}
                    updateLocation={updateUserLocation}
                    userName={userName}
                  />
                ) : null
              }
              rightMobile={
                <CartLink cartItemCount={cartItemCount} icon="bag" loading={cartLoading} total={cartTotal} />
              }
            >
              <Tabs>
                <NavTab activeOnlyWhenExact icon={<Icon>man</Icon>} title="Guest" to="/" />
                <NavTab activeOnlyWhenExact icon={<Icon>calendar</Icon>} title="Details" to="/event" />
                <NavTab activeOnlyWhenExact icon={<Icon>sandwich</Icon>} title="Menu" to="/menu" />
                <NavTab activeOnlyWhenExact icon={<Icon>payment</Icon>} title="Payment" to="/payment" />
                <MoreTab icon={<Icon>menu</Icon>} location={selectedLocation} title="More" to="/more" />
              </Tabs>
            </Navigation>
            <ErrorMessage />
            <Switch location={isModal ? previousLocation : location}>
              <Route component={Guest} exact path="/" />
              <Route component={EventDetails} exact path="/event" />
              <Route component={Menu} exact path="/menu" />
              <Route component={Payment} exact path="/payment" />
              <Route component={Submit} exact path="/submit" />
              <Route component={Dashboard} exact path="/more/dashboard" />
              <Route component={More} exact path="/more" />
              <Route component={FullScreenError} exact path="/error" />
            </Switch>
            <AddNewCard />
            <FloatingMenu />
            <Cart />
          </div>
        )}

        {!appLoadingComplete && window.location.pathname !== '/error' && <InitialLoad className="" />}
        <Footer>{`Version ${ooeConstants.APP_VERSION}`}</Footer>
      </>
    </ThemeProvider>
  );
};

function mapStateToProps(state: State) {
  return {
    cartItemCount: selectCartItemsLength(state),
    cartTotal: selectTaxAndTotal(state),
    cartLoading: selectOrderIsLoading(state),
    destination: selectDestination(state),
    userName: selectUserName(state),
    locations: selectUserLocations(state),
    selectedLocation: selectLocationNumber(state),
    appLoadingComplete: selectAppLoadingComplete(state),
    userHasAgreedToEula: selectUserHasAgreedToEula(state),
    eulaText: selectEulaText(state),
    oktaError: selectOktaTokenError(state),
    displayDeviceMessage: selectDisplayMessage(state),
    userIsLoggedIn: selectIsUserLoggedIn(state),
    locationDropdownDisabled: selectLocationDropdownDisabled(state),
    shouldBypassBusinessRules: selectShouldBypassBusinessRules(state),
    isAdminUser: getIsAdminUserFromState(state),
    allStoreNamesAndNumbers: selectAllStoreNamesAndNumbers(state),
    isHardcodedEulaShown: selectShowHardcodedEulaText(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      ...menuActions,
      ...userActions,
      ...guestActions,
      ...formActions,
      ...orderActions,
      ...dashboardActions,
    },
    dispatch,
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = Partial<ConnectedProps<typeof connector> & { location: any }>;

export default withRouter(connector(App));
