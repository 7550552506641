import { TZDateMini } from '@date-fns/tz';
import {
  addHours,
  addMinutes,
  constructNow,
  endOfDay,
  format,
  getMinutes,
  isAfter,
  parse,
  parseISO,
  startOfDay,
} from 'date-fns';
import { drop, dropLast, isEmpty, join, keys, values } from 'ramda';
import { notifyBugsnag } from '../services/bugsnag';

import ooe from '../constants';

export function formatPrice(price?: any) {
  if (Number.isNaN(price) || !price) {
    return '$0.00';
  }
  return `$${parseFloat(price.toString()).toFixed(2)}`;
}

export function formatModifierPrice(price = 0.0) {
  if (price === 0.0) {
    return 'Free';
  }
  return `$${parseFloat(price.toString()).toFixed(2)}`;
}

export function formatItemName(name?: any) {
  if (!name || typeof name !== 'string') {
    return '';
  }
  const abbreviations = ooe.NAME_ABBREVIATIONS;
  return keys(abbreviations).reduce((acc, key) => {
    const replaceWith = abbreviations[key];
    const re = new RegExp(key, 'gi');
    return acc.replace(re, replaceWith);
  }, name);
}

export function formatModifier(mod: { action: string; modifierType: string; name: string }) {
  const { action, modifierType, name } = mod;
  if (!name || typeof name !== 'string') {
    return '';
  }
  if (action === 'REMOVE' || modifierType === 'RECIPE') {
    return `No ${name}`;
  }
  return `${formatItemName(name)}`;
}

export function formatEventSummaryDate(date?: Date) {
  if (!date) {
    return <span className="required">Date*</span>;
  }

  return format(date, ooe.DATE_TIME_FORMAT.eventSummaryDate);
}

export function formatEventSummaryTime(time?: string, isDelivery?: boolean) {
  if (!time) {
    return <span className="required">Time*</span>;
  }

  return formatTimePickerTime(time, isDelivery);
}

export function formatTimePickerTime(time: string, isDelivery?: boolean) {
  return (
    format(parse(time, ooe.DATE_TIME_FORMAT.time, new Date()), ooe.DATE_TIME_FORMAT.eventSummaryTime) +
    (isDelivery
      ? ' - ' +
        format(
          addMinutes(parse(time, ooe.DATE_TIME_FORMAT.time, new Date()), 15),
          ooe.DATE_TIME_FORMAT.eventSummaryTime,
        )
      : '')
  );
}

export function formatLongDate(dateTime: string) {
  return format(parseISO(dateTime), ooe.DATE_TIME_FORMAT.longDate);
}

export function formatShortDate(dateTime: string) {
  return format(parseISO(dateTime), ooe.DATE_TIME_FORMAT.shortDate);
}

export function formatPaper(paperGoods: boolean) {
  return paperGoods
    ? 'Please include plates, cutlery, napkins and cups. '
    : 'Please do not include plates, cutlery, napkins or cups. ';
}

export function formatPaperGoodsOptions(paperGoods: Record<string, boolean>) {
  if (isEmpty(paperGoods)) {
    return '';
  }
  if (!isEmpty(paperGoods) && Object.values(paperGoods).some((opt) => opt === true)) {
    const paperGoodsArr = Object.keys(paperGoods).filter((k) => paperGoods[k]);
    if (paperGoodsArr.length === 2) {
      paperGoodsArr.splice(1, 0, ' and ');
    } else if (paperGoodsArr.length === 3) {
      paperGoodsArr.splice(1, 0, ', ');
      paperGoodsArr.splice(-1, 0, ' and ');
    } else if (paperGoodsArr.length === 4) {
      paperGoodsArr.splice(1, 0, ', ');
      paperGoodsArr.splice(3, 0, ', ');
      paperGoodsArr.splice(-1, 0, ' and ');
    }
    return `Please include ${paperGoodsArr.join('')}.`;
  }
  return 'Please do not include plates, cutlery, napkins or cups.';
}

export function formatGuests(guestCount?: number) {
  return guestCount ? (
    <div>
      <span className="bold">Guest count: </span>
      {guestCount}
    </div>
  ) : (
    <div>
      <span className="bold">Guest count: </span>
    </div>
  );
}

export function formatCity(city: string) {
  return city ? `${city}, ` : <span className="required">City*</span>;
}

export function formatPOSDeliveryAddress(address: Record<string, string>) {
  return join(' ')(drop(1, dropLast(1, values(address))));
}

export function formatField(field: string, title: string) {
  return field ? `${field}` : <span className="required">{title}*</span>;
}

function validateEmail(email: string) {
  // Email validation RegEx found on SO.
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePhone(phone: string) {
  const re = /^[+]?[0-9]{0,3}[\s-]?(\d{3})[\s-](\d{3})[\s-](\d{4})/;
  return re.test(String(phone).toLowerCase());
}

export function formatPhone(phone: string) {
  return validatePhone(phone) ? `${phone}` : <span className="required">Phone Number*</span>;
}

export function formatEmail(email: string) {
  return validateEmail(email) ? `${email}` : <span className="required">Email*</span>;
}

export function capitalizeFirstLetter(input?: string) {
  if (!input) {
    return '';
  }
  const [first, ...rest] = input;
  return first ? first.toUpperCase() + rest.join('') : '';
}

export function titleCase(string = '') {
  if (!string || typeof string !== 'string') {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export function formatTierName(tier: string) {
  if (tier === 'Prospect') {
    return '';
  }
  return tier;
}

export function isTooLateToCancelOrEdit(promiseTime: string, bypassBusinessRules: boolean, timeWindow = 2) {
  if (bypassBusinessRules) {
    return false;
  }

  const timeOfOrder = parseISO(promiseTime);
  const endOfWindow = addHours(timeOfOrder, timeWindow);
  const currentTime = constructNow(new Date());
  return isAfter(currentTime, endOfWindow);
}

export function generateTimeSlots(dateIsToday: boolean, timeZone: string) {
  const timeSlots = [];
  const now = constructNow(new Date());
  let startTime = startOfDay(now);
  let endTime = endOfDay(now);
  if (dateIsToday) {
    const nowTz = constructNow(TZDateMini.tz(timeZone));
    const remainder = 15 - (getMinutes(nowTz) % 15);
    startTime = addMinutes(nowTz, remainder);
    endTime = endOfDay(nowTz);
  }
  while (startTime <= endTime) {
    timeSlots.push({
      available: true,
      time: format(startTime, ooe.DATE_TIME_FORMAT.time),
    });
    startTime = addMinutes(startTime, 15);
  }
  return timeSlots;
}

export const formatRestaurantLocalTime = (timezone: string) => {
  try {
    if (typeof timezone !== 'string' || timezone.trim() === '') {
      throw new Error(`Invalid timezone: ${timezone}`);
    }
    return format(constructNow(TZDateMini.tz(timezone)), ooe.DATE_TIME_FORMAT.timeAmPm);
  } catch (error: unknown) {
    notifyBugsnag('formatRestaurantLocalTime', {
      context: 'Invalid Timezone',
      info: { timezone, error },
    });
    return 'Timezone information could not be reached at this time.';
  }
};
