import { createSelector } from '@reduxjs/toolkit';
import {
  addDays,
  constructNow,
  format,
  isSameDay,
  parse,
  setHours,
  setMinutes,
  setSeconds,
  startOfDay,
} from 'date-fns';
import { contains, filter, find, indexBy, isEmpty, length, map, prop, propEq, reduce, values } from 'ramda';
import { reducer as formReducer } from 'redux-form';

import {
  selectDashboardErrors,
  selectIsErrorStatus,
  selectIsPOSOrder,
  selectOrderToView,
} from 'src/dashboard/selectors';
import cart, { actions as cartActions } from '../cart/reducer';
import { selectCart, selectCartItemsLength } from '../cart/selectors';
import ooeConstants from '../constants';
import {
  selectMaxDeliveryAmount,
  selectMaxPickupAmount,
  selectMaxSameDayOrderTotalAmountCents,
  selectMinDeliveryAmount,
  selectMinPickupAmount,
} from '../floatingMenu/selectors';
import { selectMenuWithMethods } from '../menu/selectors';
import type { MenuItem } from '../types/menu';
import { dashboardErrorMessages } from '../util/customerErrorMessages';
import { formatPaper, formatPaperGoodsOptions, formatPrice, isTooLateToCancelOrEdit } from '../util/format';
import { roundNumber } from '../util/utils';
import dashboard, { State as DashboardState } from './dashboard';
import device, { actions as deviceActions, State as DeviceState } from './device';
import {
  State as FormState,
  selectDate,
  selectDetailsFormValues,
  selectNewPaymentMethod,
  selectPaymentForm,
  selectSecondaryContactValues,
  selectTime,
} from './form';
import guest, {
  actions as guestActions,
  State as GuestState,
  selectCardSelected,
  selectPayment,
} from './guest';
import menu, { actions as menuActions, State as MenuState } from './menu';
import order, {
  actions as orderActions,
  State as OrderState,
  selectDeliveryTip,
  selectDestination,
  selectEditMode,
  selectIsEditCMTOrder,
  selectOrderForDXE,
  selectPaperGoodsOptions,
  selectPaperGoodsYesOrNo,
  selectSubTotalAmount,
  selectTaxAmount,
} from './order';
import user, {
  selectBypassBusinessRules,
  selectLocationAcceptsDelivery,
  selectLocationAcceptsPickup,
  selectLocationNumber,
  actions as userActions,
  State as UserState,
} from './user';

export type State = {
  cart: CartState;
  dashboard: DashboardState;
  device: DeviceState;
  form: FormState;
  guest: GuestState;
  menu: MenuState;
  order: OrderState;
  user: UserState;
};

export default {
  cart,
  menu,
  user,
  order,
  guest,
  device,
  form: formReducer,
  dashboard,
};

export const combinedActionCreators = {
  ...cartActions,
  ...menuActions,
  ...userActions,
  ...orderActions,
  ...guestActions,
  ...deviceActions,
};

/**
 * Dashboard Selectors
 */

export const selectTooLateToCancelOrEdit = createSelector(
  selectBypassBusinessRules,
  selectOrderToView,
  (bypassBusinessRules, orderToView) => {
    const { status, promiseDateTime } = orderToView;
    const cancelled = contains(status, ooeConstants.CANCELLED);
    const tendered = status === ooeConstants.FINALIZED;
    const tooLate = promiseDateTime && isTooLateToCancelOrEdit(promiseDateTime, bypassBusinessRules);
    return (tooLate && !cancelled) || tendered;
  },
);

export const selectDetailViewErrors = createSelector(
  selectTooLateToCancelOrEdit,
  selectIsErrorStatus,
  selectDashboardErrors,
  selectIsPOSOrder,
  (tooLate, errorStatus, errors, isPOSOrder) => {
    let detailViewErrors = Object.values(errors);
    if (tooLate) {
      detailViewErrors.push(dashboardErrorMessages.TOO_LATE_TO_CANCEL);
    }
    if (errorStatus) {
      detailViewErrors.push(dashboardErrorMessages.ERROR_STATUS);
    }
    if (isPOSOrder) {
      detailViewErrors = [errors.orderDetails];
      detailViewErrors.push(dashboardErrorMessages.POS_ORDER);
    }
    return detailViewErrors;
  },
);

/**
 * Event Details Selectors
 */

export const selectDateIsToday = createSelector(selectEditMode, selectDate, (editMode, date) => {
  const today = startOfDay(constructNow(new Date()));
  return !!date && !editMode && isSameDay(date, today);
});

/**
 * Payment Selectors
 */

export const selectPaymentValid = createSelector(selectCardSelected, selectPaymentForm, (card, payment) => {
  if (payment && payment.values) {
    if (payment.values.selectedMethod === ooeConstants.CREDIT) {
      return !isEmpty(card) && card !== 'error';
    }
    return true;
  }
  return true;
});

export const selectPayWithVaultedCard = createSelector(
  selectPaymentForm,
  selectPaymentValid,
  (form, paymentValid) =>
    form && form.values && form.values.selectedMethod === ooeConstants.CREDIT && paymentValid,
);

/**
 * Cart Selectors
 */

export const selectAllSellableItems = createSelector(
  selectMenuWithMethods,
  reduce((all, category) => [...all, ...category.items], [] as MenuItem[]),
);

function indexByTag<T extends Record<'tag', string>>(list: T[]) {
  return indexBy(prop<'tag', string>('tag'), list);
}

export const selectIndexedSellableItems = createSelector(selectAllSellableItems, (sellableItems) => {
  // TODO: this should probably happen in the base menu selectors and just do the indexing here
  const allIndexedItems = map((sellableItem) => {
    let freeSauces: number | undefined;
    const reducedItems = reduce(
      (all, category) => {
        // if free sauces — get max, but don't add to list
        if (category.sauceType === 'Free') {
          freeSauces = category.maximum;
          return [...all];
        }
        if (category.sauceType === 'Paid') {
          const o = map((c) => ({ ...c, isSauce: true }), category.items || []);
          return [...all, ...o];
        }

        if (category.comboTag) {
          const o = map((c) => ({ ...c, comboTag: category.comboTag }), category.items || []);
          return [...all, ...o];
        }

        const items = category.items || [];
        return [...all, ...items];
      },
      [] as MenuItem[],
      sellableItem.items || [],
    );
    const indexedItems = indexByTag(reducedItems);
    return { ...sellableItem, indexedItems, freeSauces };
  }, sellableItems);
  return indexByTag(allIndexedItems);
});

export const selectCartWithPrices = createSelector(
  selectIndexedSellableItems,
  selectCart,
  (flattenedMenu, cartItems) => {
    const cartItemsWithPrices = map(
      (item: CartState[number]) => {
        let selectedSide;
        let selectedDessert;
        const { tag, modifiers, selectedSideTag, selectedDessertTag, promoFree } =
          item as CartState[number] & { tag: string };
        const { freeSauces, sideItems, dessertItems } = flattenedMenu[tag];
        let freeSaucesRemaining = freeSauces ? freeSauces * item.quantity : 0;

        const calculateModQuantity = (mod: CartItemModifier) => {
          if (contains(mod.tag, ooeConstants.TOGGLEABLE_ITEM_TAGS)) {
            return mod.quantity * item.quantity;
          }
          return mod.quantity;
        };

        const calculateModPrice = (mod: CartItemModifier) => {
          const menuMod = flattenedMenu[tag].indexedItems[mod.tag];
          if (!menuMod || !Object.prototype.hasOwnProperty.call(menuMod, 'itemPrice')) {
            // eslint-disable-next-line no-console
            console.warn(`${tag} does not have ${mod.tag} as an option`);
            return 0;
          }
          const { itemPrice } = menuMod;
          const displayQuantity = calculateModQuantity(mod);

          if (menuMod.isSauce === true) {
            const paidQuantity = Math.max(displayQuantity - freeSaucesRemaining, 0);
            freeSaucesRemaining += -(displayQuantity - paidQuantity);
            return itemPrice * paidQuantity;
          }
          return itemPrice * displayQuantity;
        };

        if (selectedSideTag) {
          const foundSide = sideItems
            ? find(propEq('tag', selectedSideTag), sideItems) || sideItems[0]
            : { tag: selectedSideTag, itemPrice: 0 };

          selectedSide = {
            ...foundSide,
            price: item.quantity * foundSide.itemPrice,
            displayQuantity: item.quantity,
          };
        }

        if (selectedDessertTag) {
          const foundDessert = dessertItems
            ? find(propEq('tag', selectedDessertTag), dessertItems) || dessertItems[0]
            : { tag: selectedDessertTag, itemPrice: 0 };
          selectedDessert = {
            ...foundDessert,
            price: item.quantity * foundDessert.itemPrice,
            displayQuantity: item.quantity,
          };
        }

        return {
          ...flattenedMenu[tag],
          ...item,
          selectedSide,
          selectedDessert,
          modifiers: map(
            (mod) => ({
              ...flattenedMenu[tag].indexedItems[mod.tag],
              ...mod,
              displayQuantity: calculateModQuantity(mod),
              price: calculateModPrice(mod),
            }),
            modifiers ? values(modifiers) : ([] as CartItemModifier[]),
          ),
          items: map(
            (i) => ({
              ...i,
              quantity: prop('quantity', modifiers ? modifiers[i.tag] : { tag: '', quantity: 0 }),
            }),
            values(flattenedMenu[tag].indexedItems),
          ),
          price: promoFree
            ? ooeConstants.PROMO_FREE_ITEM_PRICE
            : flattenedMenu[tag].itemPrice * item.quantity,
        };
      },
      filter(
        (cartItem) => typeof cartItem.tag === 'string' && typeof flattenedMenu[cartItem.tag] === 'object',
        cartItems,
      ),
    );
    return cartItemsWithPrices;
  },
);

export function selectModifiersForLineItems(
  modifiers: ReturnType<typeof selectCartWithPrices>[number]['modifiers'],
) {
  return modifiers.map((mod) => {
    return {
      action: mod.modifierType === 'RECIPE' ? 'REMOVE' : 'ADD',
      itemTag: mod.tag,
      name: mod.name,
      quantity: mod.quantity,
      comboTag: mod.comboTag,
    };
  });
}

function mapCartItems(
  items: ReturnType<typeof selectCartWithPrices> | MenuItem[],
  comboModifiers?: ReturnType<typeof selectCartWithPrices>[number]['modifiers'],
  selectedSide?: ReturnType<typeof selectCartWithPrices>[number]['selectedSide'],
  selectedDessert?: ReturnType<typeof selectCartWithPrices>[number]['selectedDessert'],
  comboItemQty = 1, //hardcode combo item quantity because promo free doesn't work without it
): Array<{
  itemTag: string;
  quantity: number;
  name?: string;
  specialInstructions?: string;
  modifiers: ReturnType<typeof selectModifiersForLineItems>;
  comboItems?: ReturnType<typeof mapCartItems>;
  dayPart?: string;
  promoFree?: boolean;
}> {
  return items.map((item) => {
    let comboItems: ReturnType<typeof mapCartItems>[number]['comboItems'] | undefined;
    let modifiers = (item as ReturnType<typeof selectCartWithPrices>[number]).modifiers;

    if (item.comboItems) {
      comboItems = mapCartItems(
        (item as ReturnType<typeof selectCartWithPrices>[number]).comboItems || [],
        (item as ReturnType<typeof selectCartWithPrices>[number]).modifiers,
        (item as ReturnType<typeof selectCartWithPrices>[number]).selectedSide,
        (item as ReturnType<typeof selectCartWithPrices>[number]).selectedDessert,
      );
      modifiers = [];
    }

    let finalItem: (typeof item | typeof selectedSide | typeof selectedDessert) & {
      tag: string;
      quantity?: number;
      name?: string;
      specialInstructions?: string;
      dayPart?: string;
      promoFree?: boolean;
    } = { ...item };
    if (selectedSide && item.itemGroupType === 'Side') {
      finalItem = selectedSide;
    }

    if (selectedDessert && item.itemType === 'DESSERTS_GROUP') {
      finalItem = selectedDessert;
    }

    if (comboModifiers) {
      modifiers = filter((val) => val.comboTag === finalItem.tag, comboModifiers);
    }

    return {
      itemTag: finalItem.tag,
      quantity: finalItem.quantity || comboItemQty,
      name: finalItem.name,
      specialInstructions: finalItem.specialInstructions,
      modifiers: selectModifiersForLineItems(modifiers),
      comboItems,
      dayPart: finalItem.dayPart,
      promoFree: finalItem.promoFree,
    };
  });
}

export const selectCartForLineItems = createSelector(selectCartWithPrices, (cartItems) =>
  mapCartItems(cartItems),
);

export const selectCartHasBreakfastItems = createSelector(
  selectCartWithPrices,
  (items) => length(items.filter((item) => item.dayPart === ooeConstants.DAY_PART_BREAKFAST)) > 0,
);

/**
 * Form selectors
 */
export const selectTimes = createSelector(
  selectBypassBusinessRules,
  selectDate,
  selectTime,
  selectCartHasBreakfastItems,
  selectCartItemsLength,
  (bypassBusinessRules, date, time, containsBreakfast, cartLength) => {
    let promiseDateTime;
    let promiseTimeType;
    let businessDate;
    const automaticTime = format(
      // default time for initializing orders that don't have promiseDateTime, with this set to next day
      // 'promiseDateTime too early' errors can be generated from order api which causes issues
      // see CMT-1386 for more details: https://cfacorp.atlassian.net/browse/CMT-1386
      setSeconds(setMinutes(setHours(addDays(constructNow(new Date()), 2), 1), 0), 0),
      ooeConstants.DATE_TIME_FORMAT.dateTime,
    );
    if (date) {
      businessDate = format(date, ooeConstants.DATE_TIME_FORMAT.date);
    }
    if (date && time) {
      promiseDateTime = format(
        parse(time, ooeConstants.DATE_TIME_FORMAT.time, date),
        ooeConstants.DATE_TIME_FORMAT.dateTime,
      );
    } else if (bypassBusinessRules && !date) {
      promiseDateTime = automaticTime;
    } else if (bypassBusinessRules && date) {
      promiseDateTime = format(setHours(date, 1), ooeConstants.DATE_TIME_FORMAT.dateTime);
    } else if (cartLength > 0) {
      promiseTimeType = containsBreakfast
        ? ooeConstants.EARLIEST_AVAILABLE_BREAKFAST
        : ooeConstants.EARLIEST_AVAILABLE;
      //this is added because an order api respond with an error if we send only promiseTimeType when the BBR off and item is added to the cart
      promiseDateTime = automaticTime;
    }
    return {
      promiseDateTime,
      promiseTimeType,
      businessDate,
      date,
      time,
    };
  },
);

export const selectEventDetails = createSelector(
  selectDetailsFormValues,
  selectTimes,
  selectPaperGoodsOptions,
  selectPaperGoodsYesOrNo,
  selectIsEditCMTOrder,
  (details, times, paperGoodsOptions, paperGoodsYesOrNo, isCMT) => {
    const { paperGoods, specialInstructions, guestCount, cateringReason } = details;
    const { promiseDateTime, promiseTimeType, businessDate } = times;
    const specialInstructionsMessage = !isCMT
      ? `${formatPaperGoodsOptions(paperGoodsOptions)} ${specialInstructions || ''}`
      : specialInstructions || formatPaper(paperGoods);
    const paperGoodsBool = !isCMT ? paperGoodsYesOrNo === 'yes' : paperGoods;
    return {
      specialInstructions: specialInstructionsMessage,
      paperGoods: paperGoodsBool,
      guestCount,
      businessDate,
      promiseDateTime,
      promiseTimeType,
      cateringReason,
    };
  },
);

/**
 * Order Selectors
 */

export const selectOrderForAPI = createSelector(
  selectOrderForDXE,
  selectEventDetails,
  selectLocationNumber,
  selectCartForLineItems,
  selectBypassBusinessRules,
  selectSecondaryContactValues,
  selectDeliveryTip,
  (
    orderDetails,
    eventDetails,
    locationNumber,
    lineItems,
    bypassBusinessRules,
    secondaryContact,
    deliveryTip,
  ) => {
    const lineItemsToUse = lineItems.map((lineItem) => {
      let { modifiers } = lineItem;
      const { comboItems } = lineItem;
      if (lineItem.comboItems) {
        modifiers.forEach((mod) => {
          comboItems?.forEach((comboItem, i) => {
            if (mod.comboTag === comboItem.itemTag) {
              const newMods = comboItem.modifiers || [];
              if (!newMods.find((aMod) => aMod.itemTag)) {
                newMods.push(mod);
              }
              comboItems[i].modifiers = newMods;
            }
          });
        });
        modifiers = [];
      }
      return {
        ...lineItem,
        modifiers,
        comboItems,
      };
    });

    const isNotEmptyValues = Object.values(secondaryContact).some(
      (x) => x !== null && x !== '' && typeof x === 'string',
    );

    const isObjectWithKeys = (value: any) =>
      !!(value && typeof value === 'object' && value.firstName && value.lastName && value.phoneNumber);

    const secondaryContactIsValid = isObjectWithKeys(secondaryContact);

    let orderToSend: typeof orderDetails &
      typeof eventDetails & {
        locationNumber: typeof locationNumber;
        lineItems?: typeof lineItemsToUse;
        deliveryTip: typeof deliveryTip;
        bypassBusinessRulesList?: string[];
        secondaryContact?: typeof secondaryContact;
      } = {
      ...orderDetails,
      ...eventDetails,
      locationNumber,
      lineItems: lineItemsToUse,
      deliveryTip,
    };
    if (orderToSend.lineItems?.length === 0) {
      orderToSend = { ...orderToSend, status: 'Cart', lineItems: undefined };
    }
    if (bypassBusinessRules) {
      orderToSend = {
        ...orderToSend,
        bypassBusinessRulesList: ['all'],
      };
    }
    if (secondaryContactIsValid && isNotEmptyValues) {
      orderToSend = {
        ...orderToSend,
        secondaryContact,
      };
    }
    return orderToSend;
  },
);

export const selectOrderWithPayment = createSelector(
  selectOrderForAPI,
  selectPayWithVaultedCard,
  selectNewPaymentMethod,
  selectPayment,
  (orderState, payWithVaultedCard, newPaymentMethod, payment) => {
    if (payWithVaultedCard) {
      return { ...orderState, payment, status: 'Submit' };
    }
    if (!newPaymentMethod) {
      return {
        ...orderState,
        payment: { paymentType: 'TO_BE_COLLECTED' },
        status: 'Submit',
      };
    }
    return { ...orderState, status: 'Cart' };
  },
);

export const selectTaxAndTotal = createSelector(
  selectTaxAmount,
  selectSubTotalAmount,
  (tax = 0, subtotal = 0) => formatPrice(tax + subtotal),
);

export const selectDeliveryMinNotMet = createSelector(
  selectDestination,
  selectSubTotalAmount,
  selectMinDeliveryAmount,
  (destination, subtotal, minDeliveryAmount) => {
    return (
      destination === 'Delivery' &&
      typeof minDeliveryAmount === 'number' &&
      subtotal * 100 <= minDeliveryAmount
    );
  },
);

export const selectPickupMinNotMet = createSelector(
  selectDestination,
  selectSubTotalAmount,
  selectMinPickupAmount,
  (destination, subtotal, minPickupAmount) =>
    destination === 'Pickup' && typeof minPickupAmount === 'number' && subtotal * 100 <= minPickupAmount,
);

export const selectMaxOrderAmount = createSelector(
  selectDestination,
  selectMaxDeliveryAmount,
  selectMaxPickupAmount,
  (destination, deliveryMax, pickupMax) => {
    let maxOrderAmount;
    if (destination === 'Delivery') {
      maxOrderAmount = deliveryMax;
    } else if (destination === 'Pickup') {
      maxOrderAmount = pickupMax;
    }
    return maxOrderAmount;
  },
);

export const selectExceedsMaxOrderTotal = createSelector(
  selectSubTotalAmount,
  selectMaxOrderAmount,
  (subtotal, maxOrderTotalAmount) =>
    typeof maxOrderTotalAmount === 'number' && subtotal * 100 > maxOrderTotalAmount,
);

export const selectExceedsSameDayMaxOrderTotal = createSelector(
  selectSubTotalAmount,
  selectMaxSameDayOrderTotalAmountCents,
  selectDateIsToday,
  (subtotal, maxSameDayOrderTotalAmount, dateIsToday) => {
    return (
      dateIsToday &&
      typeof maxSameDayOrderTotalAmount === 'number' &&
      subtotal * 100 > maxSameDayOrderTotalAmount
    );
  },
);

export const selectDestinationNotValidForLocation = createSelector(
  selectDestination,
  selectLocationAcceptsDelivery,
  selectLocationAcceptsPickup,
  (destination, acceptsDelivery, acceptsPickup) =>
    (destination === ooeConstants.DELIVERY && !acceptsDelivery) ||
    (destination === ooeConstants.PICKUP && !acceptsPickup),
);

export const selectPromoFreeItemsValue = createSelector(selectCartWithPrices, (cartWithPrices) => {
  const promoFreeItemsValue = cartWithPrices
    .filter((x) => x.promoFree)
    .reduce((accumulator, current) => accumulator + (current.itemPrice * current.quantity || 0), 0);
  return roundNumber(promoFreeItemsValue);
});
